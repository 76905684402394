import React, { useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Container, Row, Col } from "reactstrap";
import Dots from 'react-carousel-dots';
import Carousel from "react-multi-carousel";
import CarouselCard from './CarouselCard';
import CarouselCardMobile from './CarouselCardMobile';




const Wrapper = styled.div`

  // @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
  //   padding: 100px 0;
  // }

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  // & > .heading {
  //   color: #000000;
  //   font-family: ${props => props.theme['$font-secondary-medium']};
  //   font-size: ${props => props.theme['$font-size-lg']};
  //   font-weight: ${props => props.theme['$weight-regular']};
  //   letter-spacing: 1px;
  //   line-height: 57px;
  // }

  // & > .link {
  //   padding-top: 0;
  //   padding-bottom: 50px;
  // }

  // & > .row {
  //   width: 100%;
  //   justify-content: center;
  //   @media (min-width: ${props => props.theme['mobileBreakpoint']}) {
  //     padding: 0px 50px;
  //   }
  // }


  .main-card {
    display:flex;
    flex-direction: row;
}

.active {
  background-color: 'black';
}

.wideview-web {
  cursor: auto;
  display: none;
  visibility: collapse;
  @media (min-width: 1140px) {
    visibility: visible;
    display: flex;
  }
}

.wideview-tab {
  display: none;
  visibility: collapse;
  @media (min-width: 768px) and (max-width:1139px) {
    visibility: visible;
    display: flex;
  }
}

.narrowview {
  display: none;
  visibility: collapse;
  @media (max-width: 767px) {
    visibility: visible;
    display: flex;
  }
}

.inside-issue .img {
  max-height: 250px;
  max-width: 200px:
  
}

.inside-issue .image {
  max-width: 100%;
  width: 200px
  // max-height: 250px;
  
}



// .react-multi-carousel-item {
//  width: unset !important;
// }

.inside-issue {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-middle: true;
  justify-content:center;
  cursor:auto;
  
  @media (max-width: 767px) {
    height: 100%;
    box-shadow: 2px 5px 5px #0000001A;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  

}

.inside-arrow .arrow-left-inside {

  position: absolute !important;
  height: 30px;
  width: 30px;
  left: -25px;
  @media (max-width: 1139px) {
    left: -10px;
    height: 20px;
    width: 20px;
  }
  top: 50%;
  z-index: 5;
  border: 1px solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 2px;
  visibility: visible;
  opacity: 0.8;
  cursor: pointer;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
  

  .inside-arrow .arrow-right-inside {

    position: absolute !important;
    height: 30px;
    width: 30px;
    right: -25px;
    @media (max-width: 1139px) {
      right: -10px;
      height: 20px;
      width: 20px;
    }
    top: 50%;
    z-index: 5;
    border: 1px solid grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    visibility: visible;
    padding: 2px;
    opacity: 0.8;
    cursor: pointer;
    -webkit-transform: rotate(
      -45deg
      );
          transform: rotate(
      -45deg
      );
  
    }

    iframe {
      width: unset;
      height: unset;
      width: 100%;
      aspect-ratio: 560/315;
    }

    .video-embed {
      padding-left: 0px;
      padding-right: 0px;
    }

    .react-multi-carousel-list {
      padding: unset;
    }

    .inside-issue-content {
      color: #1c1c1c;
      font-size: 18px;
      @media (max-width: 1139px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .narrow-col {
      padding-bottom: 45px;
      @media (max-width: 767px) {
        padding-bottom: 40px;
      } 
    }

`;

const LoadProductPage = gql`
query LoadProductPage($productId: String!) { 
  productPage (productId:$productId) { 
    id 
    slug 
    title 
    productId 
    showCarouselOrVideo 
    insideThisIssueContent 
    youtubeVideoEmbedLink 
    carouselSlides { 
      id 
      slideType 
      slideNumber 
      leftCardTitle 
      leftCardAuthorName 
      leftCardAuthorProfile 
      leftCardBackgroundColour { 
        displayName 
        value 
      } 
      leftCardFontColour { 
        displayName 
        value 
      } 
      centerCardImage 
      centerCardTitle 
      centerCardCredits 
      centerCardFontColour { 
        displayName 
        value 
      } 
      rightCardSynopsis 
      rightCardBackgroundColour { 
        displayName 
        value 
      } 
      rightCardFontColour { 
        displayName 
        value 
      } 
    } 
  }  
}
`;



const responsive = {

  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    swipeable: false

  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    showDots: true

  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    showDots: true,
    partialVisibilityGutter: 10,

  }

};

const CustomButtonGroupAsArrows = ({ fullLength, length, next, previous, goToSlide, activeDot, setActiveDot, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const dots = Array.from(Array(length).keys());

  const [previousSlide, setPreviousSlide] = useState(-1);


  return (


    <div
      className="inside-arrow"
      style={{
        textAlign: "center",
      }}
    >
      <i onClick={() => {
        if (activeDot === 1 || activeDot === length - 1) {
          previous()
          setActiveDot(activeDot - 1);
        }
        else if (activeDot != 0 && (currentSlide - 1) % 3 == 0) {
          goToSlide(currentSlide - 3);
          setActiveDot(activeDot - 1);
        }
        else if (activeDot != 0 && (currentSlide - 1) % 3 != 0) {
          goToSlide(currentSlide - ((currentSlide - 1) % 3));
          setActiveDot(activeDot - 1);
        }
      }} className="arrow-left-inside">  </i>
      <i onClick={() => {
        if (currentSlide == 0 || activeDot === length - 2) {
          next();
          setActiveDot(activeDot + 1);
        }
        else if (activeDot != length - 1 && (currentSlide - 1) % 3 == 0) {
          goToSlide(currentSlide + 3);
          setActiveDot(activeDot + 1);
        }
        else if (activeDot != length - 1 && (currentSlide - 1) % 3 != 0) {
          goToSlide(currentSlide + 3 - ((currentSlide - 1) % 3));
          setActiveDot(activeDot + 1);
        }
      }}  className="arrow-right-inside">  </i>
      {/* <ul className="react-multi-carousel-dot-list" >
        {
          dots.map(
            (product, id) => (
              <li key={id} onClick={() => setActiveDot(id)} class={`react-multi-carousel-dot ${activeDot === id ? 'react-multi-carousel-dot--active' : 'inactive'}`}>
                <button
                  aria-label="Go to slide 5"
                  onClick={() => id === 0 ? goToSlide(id) : id === length - 1 ? goToSlide(fullLength - 3) : goToSlide((id - 1) * 3 + 1)}>
                </button></li>
            )
          )

        }
      </ul> */}

      <div className="d-flex flex-row justify-content-center" style={{paddingTop:'35px'}}>
        <Dots  length={length} active={activeDot} visible={6} size={12} margin={3}/>
      </div>
     
      
    </div>
  );
};

const CustomButtonGroupAsArrows2 = ({fullLength, length, next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  return (
    <div
      className="inside-arrow"
      style={{
        textAlign: "center",
      }}
    >
     <div className="d-flex flex-row justify-content-center" style={{paddingTop:'35px'}}>
        <Dots  length={length} active={currentSlide} visible={6} size={12} margin={3}/>
      </div>
    </div>
  );
};

const url = 'https://www.thecollector.com/wp-content/uploads/2020/06/giulio-romano-olympian-gods-wall-painting-1.jpg'

function expandSlides(carouselSlides) {
  const expandedData = [];
  carouselSlides.forEach(
    slide => {
      if (slide.slideType === 'A_1') {
        expandedData.push({
          slideType: 'A_1',
          slideSide: 'left',
          slideNumber: slide.slideNumber,
          leftCardAuthorName: slide.leftCardAuthorName,
          leftCardAuthorProfile: slide.leftCardAuthorProfile,
          leftCardBackgroundColour: slide.leftCardBackgroundColour != undefined? slide.leftCardBackgroundColour.value : darkColours[slide.slideNumber%3],
          leftCardFontColour: slide.leftCardFontColour,
          leftCardTitle: slide.leftCardTitle,
    
        });
      }
      expandedData.push({
        ...slide,
        slideSide: 'center'
      });
      if (slide.slideType === 'A_1') {
        expandedData.push({
          slideType: 'A_1',
          slideSide: 'right',
          slideNumber: slide.slideNumber,
          rightCardSynopsis: slide.rightCardSynopsis,
          rightCardBackgroundColour: slide.rightCardBackgroundColour != undefined ? slide.rightCardBackgroundColour.value : lightColours[slide.slideNumber%3],
          rightCardFontColour: slide.rightCardFontColour,
        });
      }

    }
  );
  return expandedData;
}



function compare(a, b) {
  if (a.slideType < b.slideType) {
    return -1;
  }
  else if (a.slideType > b.slideType) {
    return 1;
  }
  else {
    if (a.slideNumber < b.slideNumber) {
      return -1;
    }
    else if (a.slideNumber > b.slideNumber) {
      return 1;
    }
  }
  return 0;
}

const darkColours = ['#141D60', '#EB2027', '#F05C40'];
const lightColours =  ['#FFFBE3', '#FAAD42', '#E0E5D9'];






export const InsideThisIssue = ({ id }) => {

  const [activeDot, setActiveDot] = useState(0);

  return (
    <Wrapper>
      <Query
        query={LoadProductPage}
        fetchPolicy="no-cache"
        variables={
          {

            productId: id

          }
        }
      >
        {
          ({ loading, error, data }) => {
            if (loading) {

              return <h1>loading..</h1>;
            }
            if (error) {

              console.log(error);

              return <div></div>;
            }


            const {
              id,
              productId,
              insideThisIssueContent,
              showCarouselOrVideo,
              slug,
              title,
              youtubeVideoEmbedLink,
              carouselSlides
            } = data.productPage || {};

            if (showCarouselOrVideo === 'A_0' && carouselSlides.length == 0) {
              return <div></div>; 
            }
            if (carouselSlides == undefined){

              return <div></div>; 
            } 
            carouselSlides.sort(compare);
            const expandedData = expandSlides(carouselSlides);
          
            console.log('Called');
            return <>
              <Row className="text-center" style={{ alignItems: 'center', justifyContent: 'center' }}>
                <h3 key="past-issues" className="heading-past my-5 text-center" >
                  Inside this Issue
                      </h3>
              </Row>
              {
                showCarouselOrVideo === 'A_0' &&
                <Row className="wideview-web" >
                  <Col lg='12' className="px-3 col-sm-12" style={{
                    paddingBottom: '10px',
                    position: 'relative',

                  }}>

                    <Carousel
                      items={1}
                      additionalTransfrom={0}
                      arrows={false}
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      // customRightArrow={<CustomRightArrow />}
                      // customLeftArrow={<CustomLeftArrow />}
                      customButtonGroup={<CustomButtonGroupAsArrows length={carouselSlides.length} fullLength={expandedData.length} activeDot={activeDot} setActiveDot={setActiveDot} />}
                      containerClass="container"
                      dotListClass=""
                      draggable={false}
                      focusOnSelect={false}
                      partialVisible={false}
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={true}
                      renderArrowsWhenDisabled={true}
                      showDots={false}
                      renderDotsOutside
                      responsive={responsive}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable={false}
                      afterChange={(previousSlide, { currentSlide, onMove }) => {
                        if (currentSlide === 0) {
                          setActiveDot(0);
                        }
                        else if (currentSlide >= expandedData.length - 3) {
                          setActiveDot(carouselSlides.length - 1);
                        }
                        else if ((currentSlide - 1) % 3 === 0) {
                          console.log(currentSlide / 3);
                          setActiveDot((currentSlide - 1) / 3 + 1);
                        }
                      }}
                    >
                      {expandedData.map(
                        (slide, id) => (
                          < CarouselCard slide={slide} key={id} />

                        )
                      )
                      }

                    </Carousel>

                  </Col>

                </Row>
              }
              {
                showCarouselOrVideo === 'A_0' &&
                <Row className="wideview-tab" >
                  <Col lg='12' className="px-3 col-sm-12" style={{
                    paddingBottom: '10px',
                    position: 'relative',

                  }}>

                    <Carousel
                      items={1}
                      additionalTransfrom={0}
                      arrows={false}
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      // customRightArrow={<CustomRightArrow />}
                      // customLeftArrow={<CustomLeftArrow />}
                      customButtonGroup={<CustomButtonGroupAsArrows length={carouselSlides.length} fullLength={expandedData.length} activeDot={activeDot} setActiveDot={setActiveDot} />}
                      containerClass="container"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      partialVisible={false}
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={true}
                      renderArrowsWhenDisabled={true}
                      showDots={false}
                      renderDotsOutside
                      responsive={responsive}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                      afterChange={(previousSlide, { currentSlide, onMove }) => {
                        if (currentSlide === 0) {
                          setActiveDot(0);
                        }
                        else if (currentSlide >= expandedData.length - 3) {
                          setActiveDot(carouselSlides.length - 1);
                        }
                        else if ((currentSlide - 1) % 3 === 0) {
                          console.log(currentSlide / 3);
                          setActiveDot((currentSlide - 1) / 3 + 1);
                        }
                      }}
                    >
                      {expandedData.map(
                        (slide, id) => (
                          < CarouselCard slide={slide} key={id} />

                        )
                      )
                      }

                    </Carousel>

                  </Col>

                </Row>
              }
              {
                showCarouselOrVideo === 'A_0' &&
                <Row className="narrowview">
                  <Col lg='12' className="col-sm-12" style={{
                    position: 'relative'
                  }}>

                    <Carousel
                      items={1}
                      additionalTransfrom={0}
                      arrows={false}
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      // customRightArrow={<CustomRightArrow />}
                      // customLeftArrow={<CustomLeftArrow />}
                      customButtonGroup={<CustomButtonGroupAsArrows2 length={carouselSlides.length} fullLength={expandedData.length}/>}
                      containerClass="container"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      partialVisible={true}
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={true}
                      renderArrowsWhenDisabled={true}
                      showDots={false}
                      renderDotsOutside
                      responsive={responsive}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                      afterChange={(previousSlide, { currentSlide, onMove }) => {

                      }}
                    >
                      {carouselSlides.map(
                        (slide, id) => (
                          < CarouselCardMobile slide={slide} key={id} />

                        )
                      )
                      }

                    </Carousel>

                  </Col>

                </Row>
              }
              {
                showCarouselOrVideo === 'A_1' &&
                <Row>
                  <Col lg="12 d-flex flex-row justify-content-center video-embed" >
                    <div style={{ width: '100%' }} className="d-flex flex-row justify-content-center" dangerouslySetInnerHTML={{ __html: youtubeVideoEmbedLink ? youtubeVideoEmbedLink : "" }} />
                  </Col>

                </Row>
              }

              {
                data.productPage.insideThisIssueContent != "" &&
                <Row>
                  <Col>
                    <div className="inside-issue-content" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                      {data.productPage.insideThisIssueContent}
                    </div>


                  </Col>
                </Row>
              }





            </>
          }
        }
      </Query>

    </Wrapper>

  )
}