import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import { Redirect } from "react-router";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import {
  replaceStaticUrl,
  getEditorName,
} from "./../../utils/";
import { Container, Row, Col } from "reactstrap";
import Article from "./../Article";
import { ProductPricing } from "./ProductPricing.jsx";
import Description from "./Description";
import Breadcrumbs from "../Breadcrumbs";
import FbPixel from "../../utils/fbPixel";
import BottomBar from './BottomBar';
import Carousel from "react-multi-carousel";
import ProductCard from './ProductCard'
import { PreviousIssues } from "./LatestIssues";
import { ProductsSection } from "../Home/ProductsSection";
import MagazineProduct from "./MagazineProduct";
import OtherProducts from "./OtherProducts";



//Add subtitle

const PRODUCT_QUERY = `
  product(id: $id) {
    id
    name
    subTitle
    description
    volumeInfo
    isAvailable
    isCurrenctIssue
    editorType
    category{
      name
      slug
    }
    images{
      url
    }
    thumbnail{
      url
    }
    attributes{
      value{
        id
        name
        value
        slug
      }
      attribute{
        id
        name
        slug
      }
    }
    editors{
      id
      name
    }
    videoWriters {
      name
    }
    videoProducers {
      name
    }
    videoDirectors{
      name
    }
    sections{
      childProducts{
        id
        name
        description
        isAvailable
        editors {
          id
          name
        }
        pageNumber
        order
        images { url __typename }
        variants{
          id
          isDigital
          inrPrice {
            amount
            currency
            localized
          }
          usdPrice {
            amount
            currency
            localized
          }
        }
      }
    }
    variants{
      id
      isDigital
      inrPrice {
        amount
        currency
        localized
      }
      usdPrice {
        amount
        currency
        localized
      }
      inrPricing{	
        onSale	
        price{	
          net{	
            localized	
          }	
        }	
      }	
      usdPricing{	
        onSale	
        price{	
          net{	
            localized	
          }	
        }	
      }
    }
    productReviews{
      id
      content
      reviewerName
      reviewerWorkPlace
    }
  }
`;

const ME_QUERY = `
  me{
    orders(first:10, productId: $id){
      edges{
        node{
          id
          lines{
            id
            variant{
              id
              isDigital
            }
          }
        }
      }
    }
  }
`;

const LOAD_PRODUCT_AND_ORDERS = gql`
  query LoadProduct($id: ID!) {
    ${PRODUCT_QUERY}
    ${ME_QUERY}
  }
`;
const LOAD_PRODUCT = gql`
  query LoadProduct($id: ID!) {
    ${PRODUCT_QUERY}
  }
`;

const LOAD_PRODUCTS = gql`
  query LoadProducts($first: Int, $categories: [ID]!) {
    products(first: $first, categories: $categories, sortBy:{ field:DATE,direction:DESC }) {
      totalCount
      edges {
        node {
          id
          name
          variants{
            sku
            isDigital
            inrPrice {
              amount
              currency
              localized
            }
            usdPrice {
              amount
              currency
              localized
            }
            inrPricing{	
              onSale	
              price{	
                net{	
                  localized	
                }	
              }	
            }	
            usdPricing{	
              onSale	
              price{	
                net{	
                  localized	
                }	
              }	
            }
          }
          images{
            url
          }
          attributes{
            attribute{
              name
              slug
            }
            value {
              name
            }
          }
          editors {
            id
            name
          }
        }
      }
    }
  }
`;


const MovieMetaDetails = ({ label, values }) => {
  if (!values || values.length === 0) return <div></div>;
  return <div>
    {values && values.length > 0 && <span className="key">{label}</span>}
    <span className="value">
      &nbsp;
      {
        values && values.map(({ name }) => name).join(', ')
      }
    </span>
  </div>
}





const ProductDetails = ({
  match: {
    params: { id }
  },
  saveVariant,
  currency,
  isLoggedIn,
  subscriptions,
}) => {
  useEffect(() => {
    FbPixel.track("ViewContent", { 'content_ids': [id], 'content_type': 'product' })
  }, []);

  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
   
      <Query
        query={isLoggedIn ? LOAD_PRODUCT_AND_ORDERS : LOAD_PRODUCT}
        variables={{
          id
        }}
      >
        {({ loading, error, data: { me = {}, product } = {} }) => {
          if (loading) {
            return <h1>Loading...</h1>;
          }
          if (!loading && !product) {
            return <Redirect to="/404" />;
          }

          let routes = [
            {
              name: "Home",
              url: "/",
            }
          ]
          if (product && product.category) {
            routes = routes.concat({
              name: product && product.category && product.category.name,
              url: (() => {
                if (product && product.category && product.category.slug !== "videos") {
                  return `/categories?product-type=${product && product.category && product.category.slug}`;
                } else if (product && product.category && product.category.slug !== "videos") {
                  return `/movies`;
                }
              })(),
            });
          }
          routes = routes.concat({
            name: product.name,
          })

          const {
            id: productId,
            name,
            subTitle,
            volumeInfo,
            description,
            isCurrenctIssue: isCurrentIssue,
            images,
            isAvailable,
            editors,
            sections,
            variants = [],
            attributes,
            category,
            thumbnail: { url: thumbnailUrl } = {},
            editorType,
            productReviews,
            videoDirectors,
            videoProducers,
            videoWriters,
          } = product || {};

          const { orders: { edges: orderEdges = [] } = {} } = me || {};

          let currentUserSubscription;
          const isVideo = category && category.name === 'Videos';

          const metaInfo = attributes.reduce(
            (acc, { value, attribute } = {}) => {
              acc[attribute.slug] = value.name;
              return acc;
            },
            {}
          );

          const singularCategoryName =
            category && category.name && category.name.replace(/s$/gi, '');
          const articlesShouldBePurchasable =
            singularCategoryName === "Magazine" ? true : false;

          const childProducts = sections
            .reduce((acc, section) => acc.concat(section.childProducts), [])
            .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);

          if (category.name==="Magazine"){
            return (
              <MagazineProduct
                product={product}
                saveVariant={saveVariant}
                MovieMetaDetails={MovieMetaDetails}
                routes={routes}
                subscriptions={subscriptions}
                currency={currency}
                me = {me} />
            );

          }
          else{
            return (
              <OtherProducts
              product={product}
              saveVariant={saveVariant}
              MovieMetaDetails={MovieMetaDetails}
              routes={routes}
              subscriptions={subscriptions}
              currency={currency}
              me = {me} />
            );
          }
        }}
      </Query>
  );
};

export default ProductDetails;
